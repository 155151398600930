// ICONS
import TapAndPlayOutlinedIcon from "@material-ui/icons/TapAndPlayOutlined";
import AutoModeIcon from "@mui/icons-material/AutoMode";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import BuildIcon from "@material-ui/icons/Build";
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";
import StopIcon from "@material-ui/icons/Stop";
import ReceiptIcon from "@material-ui/icons/Receipt";
import CheckIcon from "@material-ui/icons/CheckBoxOutlineBlankOutlined";
import TransportIcon from "@material-ui/icons/Business";
import React from "react";
import ConstructionIcon from "@mui/icons-material/Construction";

export const drawerMenuItems = [
  {
    title: "Analytics",
    icon: <TapAndPlayOutlinedIcon />,
    items: [
      { title: "Dashboard", to: "/analytics-dashboard" },
      { title: "Movement", to: "/analytics-handling" },
      { title: "Repo Movement", to: "/analytics-repo" },
      { title: "Self Transportation", to: "/analytics-st" },
      { title: "Stock", to: "/analytics-stock-data" },
      { title: "MNR", to: "/analytics-mnr" },
      { title: "Reports", to: "/analytics-reports" },
      { title: "MNR Material", to: "/analytics-mnr-material" },
    ],
  },
  {
    title: "Automation",
    icon: <AutoModeIcon />,
    items: [
      { title: "Allotment", to: "/automation-dashboard" },
      { title: "Operations", to: "/automation-operation" },
      { title: "Masters", to: "/automation-master" },
      { title: "Non Depot Container", to: "/automation-non-depot-container" },
      {title :"Container Info",to:"/automation-container-info"},
      {title:"Receipts",to:"/automation-receipts" },
    ],
  },
  {
    title: "Dashboard",
    to: "/dashboard",
    icon: <HomeOutlinedIcon />,
    items: [],
  },
  {
    title: "Masters",
    icon: <StopIcon />,
    items: [
      { title: "Country", to: "/master/country" },
      { title: "Location", to: "/master/location" },
      { title: "Site", to: "/master/site" },
      { title: "Seal Management", to: "/master/sealManagement" },
      {
        title: "Account Master",
        items: [
          {
            title: "Role",
            to: "/account/role",
          },
          {
            title: "User",
            to: "/account/user",
          },
        ],
      },
      {
        title: "Client Master",
        items: [
          {
            title: "Client",
            to: "/master/client",
          },
          { title: "Document", to: "/master/clientDocument" },
          {
            title: "Ref Code",
            to: "/master/refcode",
          },
        ],
      },
      {
        title: "Transporter Master",
        items: [
          {
            title: "Transporter",
            to: "/master/transporter",
          },
          {
            title: "Export Cargo Type",
            to: "/master/exportCargoType",
          },
          {
            title: "Carrier Code",
            to: "/master/carrier-code",
          },
        ],
      },
      {
        title: "Container Master",
        items: [
          { title: "Size", to: "/master/containerSize" },
          { title: "Type", to: "/master/containerType" },
          {
            title: "ISO Code",
            to: "/master/containerTypeSizeCode",
          },
          {
            title: "Handling Charges",
            to: "/master/containerHandlingCharges",
          },
          {
            title: "Transportation Charges",
            to: "/master/containerTransportationCharges",
          },
          {
            title: "Ground Rent Charges",
            to: "/master/containerGroundRentCharges",
          },
        ],
      },
      {
        title: "Vessel Master",
        items: [
          {
            title: "Vessel Booking",
            to: "/master/vesselBkgNo",
          },
          {
            title: "Vessel Voyage",
            to: "/master/vesselVoyageDetail",
          },
          {
            title: "Location Code",
            to: "/master/locationCodeDetail",
          },
        ],
      },
      {
        title: "MNR Master",
        items: [
          {
            title: "Tariff",
            to: "/master/tariffDocument",
          },
          {
            title: "Staff",
            to: "/master/staffMaster",
          },
        ],
      },
    ],
  },
  {
    title: "Empty Yard",
    icon: <CheckIcon />,
    items: [
      { title: "Operations", to: "/depot" },
      { title: "Re-Generate EDI", to: "/regenerate-edi" },
      { title: "Reports", to: "/reports" },
      { title: "MNR", to: "/mnr" },
      {
        title: "Wistim Destim Repository",
        to: "/wistim-destim-s3",
      },
    ],
  },
  {
    title: "Loaded Yard",
    icon: <CheckIcon />,
    items: [{ title: "Operations", to: "/loaded-yard" }],
  },
  {
    title: "CFS/ICD",
    // to: "/mnr",
    icon: <BuildIcon />,
    items: [
      { title: "MNR", to: "/mnr" },
      { title: "Reports", to: "/reports" },
    ],
  },
  {
    title: "Billing",
    icon: <ReceiptIcon />,
    items: [
      { title: "Transactions", to: "/new-billing" },
      { title: "History", to: "/invoice-billing" },
      { title: "MNR History", to: "/mnr-history" },
    ],
  },
  {
    title: "Transportation",
    icon: <TransportIcon />,
    items: [
      {
        title: "Masters",
        items: [
          { title: "Account Master", to: "/transport/account" },
          { title: "Customer Master", to: "/transport/customer" },
          { title: "Creditor Master", to: "/transport/creditor" },
          { title: "Driver Master", to: "/transport/driver" },
          { title: "Truck Master", to: "/transport/truck" },
          { title: "Service Tax Master", to: "/transport/service" },
        ],
      },
      { title: "Daily Booking", to: "/transport/booking" },
      { title: "Purchase LR", to: "/transport/purchase" },
      { title: "Invoice LR", to: "/transport/invoice-lr" },
      {
        title: "Transactions",
        items: [
          { title: "Payment and Receipt", to: "/voucher/paymentreciept" },
          { title: "Contra Entry", to: "/voucher/contraentry" },
          { title: "Journal Vouchers", to: "/voucher/journalvoucher" },
        ],
      },
      { title: "Reports", to: "/transport/reports" },
    ],
  },
  {
    title: "Procurement",
    icon: <ConstructionIcon />,
    items: [
      { title: "ToolRoom", to: "/procurement/tools" },
      { title: "Requistion", to: "/procurement/requesition" },
      { title: "Consumption", to: "/procurement/consumption" },
      { title: "Reports", to: "/procurement/reports" },
    ],
  },
  {
    title: "Logout",
    to: "/login",
    icon: <ExitToAppOutlinedIcon />,
    submenu: [],
  },
];

export const drawerMenuItemsLoaded = [
  {
    title: "Dashboard",
    to: "/dashboard",
    icon: <HomeOutlinedIcon />,
    items: [],
  },

  {
    title: "Loaded Yard",
    icon: <CheckIcon />,
    items: [
      { title: "Operations", to: "/loaded-yard" },
      {
        title: "EDI",
        to: "/loaded-yard-edi",
      },
    ],
  },

  {
    title: "Logout",
    to: "/login",
    icon: <ExitToAppOutlinedIcon />,
    submenu: [],
  },
];

export const drawerMenuItemsServey = [
  {
    title: "Dashboard",
    to: "/dashboard",
    icon: <HomeOutlinedIcon />,
    items: [],
  },

  {
    title: "Servey",
    to: "/serveyor",
    icon: <CheckIcon />,
  },

  {
    title: "Logout",
    to: "/login",
    icon: <ExitToAppOutlinedIcon />,
    submenu: [],
  },
];
