import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  withStyles,
  makeStyles,
  TableRow,
  Typography,
  TableBody
} from "@material-ui/core";
import React, { memo } from "react";


const useStyles = makeStyles((theme) => ({
  tableContainer: {
    marginTop:"32px",
    padding:"4px",
    paddingTop:"0px",
    borderRadius:"4px",
    "&::-webkit-scrollbar": {
      height: "8px",
    },
  },
  table: {
    minWidth: 650,
    borderCollapse: "separate",
    borderSpacing: "0px 10px",
    borderColor: "transparent",
    backgroundColor: "transparent",
  },
  button: {
    marginLeft: 10,
    "&:hover": {
      cursor: "pointer",
    },
  },
  [theme.breakpoints.down("xs")]: {
    "& .MuiInputBase-input": {
      width: "200px",
      fontSize: "0.8rem",
      padding: 1,
      height: 30,
    },
  },
  fab: {
    marginRight: theme.spacing(1),
    color: "#fff",
    cursor: "pointer",
    backgroundColor: "#2A5FA5",
    margin: 10,
  },
  bottom: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  downloadButton: {
    background: "lightgreen",
    boxShadow: "0px 3px 6px #9199A14D",
    cursor: "pointer",
    "&:hover": {
      background: "lightgreen",
      boxShadow: "0px 3px 6px #9199A14D",
      cursor: "pointer",
    },
  },
  paperContainer: {
    padding: theme.spacing(2, 3),
  },
  input: {
    padding: 7,
    borderColor: "black",
  },
  noFound: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "300px",
    textAlign: "center",
    width: "100%",
  },
}));

const StyledTableCell = withStyles(() => ({
  head: {
    fontWeight: 600,
    color: "white",
    fontSize: "12px",
    textTransform: "uppercase",
    whiteSpace: "nowrap",
  },
  root: {
    borderBottom: "none",
    borderColor: "transparent",
  },
}))(TableCell);

const StyledTableRow = withStyles(() => ({
  root: {
    backgroundColor: "white",
    borderRadius: 20,
    "&:hover": {
      boxShadow: "0px 3px 6px #9199A14D",
      // cursor: "pointer",
    },
  },
}))(TableRow);

const StyledTableDataCell = withStyles(() => ({
  root: {
    fontWeight: 600,
    color: "#243545",
    fontSize: 12.5,
    paddingLeft:"20px",
    borderBottom: "none",
   
    borderColor: "transparent",
    textTransform: "uppercase",
  },
}))(TableCell);

const AutomationTable = ({ rowArray, masterArray }) => {
  const classes = useStyles();

  return (
    <TableContainer
      style={{ minHeight: 325 }}
      className={classes.tableContainer}
    >
      <Table className={classes.table} aria-label="simple table">
        <TableHead style={{backgroundColor:"#243545",borderRadius:"80px"}}>
          <TableRow>
            {rowArray?.length > 0 &&
              rowArray.map((row) => (
                <StyledTableCell key={row} >
                  {row.split("_").join(" ")}
                </StyledTableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {masterArray?.map((row, index) => (
            <StyledTableRow key={row.code}>
              {rowArray?.map((data, index) => (
                <StyledTableDataCell scope="row">
                  {row[data]}
                </StyledTableDataCell>
              ))}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      {masterArray.length === 0 && (
        <Typography variant="body2" className={classes.noFound}>
          No Data Available{" "}
        </Typography>
      )}
    </TableContainer>
  );
};

export default memo(AutomationTable);
