
import React, { memo } from "react";
import {
    CircularProgress,
    FormControl,
    IconButton,
    InputBase,
    InputLabel,
    makeStyles,
    MenuItem,
    Paper,
    Select,
  } from "@material-ui/core";
import { Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from '@mui/icons-material/Search';
import { BorderBottom } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
    searchPaper: {
      borderRadius: 0,
      backgroundColor: "transparent",
      color: theme.palette.text.primary,
      position: "relative",
      width:"calc(100% - 50%)",
      margin:"auto",
      marginLeft:"0px"
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
      padding: 7,
      [theme.breakpoints.down("xs")]: {
        padding: 1,
        fontSize: "0.8rem",
      },
    },
    inputProcess: {
      marginLeft: theme.spacing(1),
      flex: 1,
      "&.MuiInput-underline:before":{
      borderBottom:"none"
      },
      "&.MuiInput-underline:after":{
        borderBottom:"none"
        },
      padding: "0 10px",
      [theme.breakpoints.down("xs")]: {
        padding: 1,
        fontSize: "0.8rem",
      },
    },
    searchButton: {
      backgroundColor: "#FDBD2E",
      color: "#fff",
      borderRadius: "0.5rem",
      padding: "1px 4px",
      height: 40,
      width: "200px",
      "&:hover": {
        backgroundColor: "#FDBD2E",
        color: "#fff",
      },
      [theme.breakpoints.down("xs")]: {
        // padding: "1px 4px",
        paddingRight: 3,
        height: 35,
      },
    },
  }));

const AutomationSearch = ({process,handleSetProcess,searchText,handleSearchChange,loading,setLoading,handleSearchButton,handleCloseClick}) => {
    const classes = useStyles();
 

  return (
    <Paper component="form" className={classes.searchPaper} elevation={0}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"center"}
        style={{ backgroundColor: "white", padding:"4px 8px",borderRadius:"48px" }}
      >
       
        <FormControl
          variant="standard"
          style={{ marginTop: "-15px", marginLeft: "10px" }}
        >
          <InputLabel
            id="container_list_select_label"
            style={{
              color: "grey",
              zIndex: 10,
              fontSize: "15px",
              textAlign: "center",
              padding: "0 10px",
              marginTop: "-10px",
              display:"none"
            }}
          >
            Process
          </InputLabel>
          <Select
            id="=container_list_select"
            value={process}
            labelId="container_list_select_label"
            name="client"
            defaultValue={process}
            label="Process"
            variant="standard"
            onChange={handleSetProcess}
            className={classes.inputProcess}
            inputProps={{
              style: {
                padding: "0px 10px",
                marginTop: "-10px",
                 outline:"none"
              },
            }}
            style={{
              width: "100px",
              backgroundColor: "transparent",
              border:"0.5px solid rgba(0,0,0,0.2)",
           
              borderRadius: "32px",
              outline:"none"
            }}
          >
            <MenuItem key={"IN"} value="IN">
              IN
            </MenuItem>
            <MenuItem key={"OUT"} value="OUT">
              OUT
            </MenuItem>
          </Select>
        </FormControl>

        <InputBase
          id="container-search"
          name="searchText"
          className={classes.input}
          placeholder="Search for a Container"
          inputProps={{ "aria-label": "search" }}
          value={searchText}
          onChange={handleSearchChange}
          autoComplete="off"
        />
        {loading ? (
          <CircularProgress
            size={30}
            style={{ marginRight: "10px" }}
          ></CircularProgress>
        ) : (
          <IconButton onClick={handleCloseClick}>
            <CloseIcon />
          </IconButton>
        )}

        <IconButton  onClick={handleSearchButton}>
           <SearchIcon/>
        </IconButton>
      </Stack>
    </Paper>
  );
};

export default memo(AutomationSearch);
